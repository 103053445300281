<script setup lang="ts">
const { t } = useI18n()
const cartStore = useCartStore()
</script>

<template>
  <div
    v-if="cartStore.isOpen"
    class="cart-popover bg-white text-dusty-gray p-6 rounded-3xl w-[584px] opacity-1 shadow-2xl"
  >
    <RCartEmptyState v-if="cartStore.isEmpty" />

    <template v-else>
      <div class="mb-4 text-gray-400 text-sm font-bold">
        {{ t('components.cartPopover.title') }}

        <span class="font-medium text-gray-300">
          {{ t('components.cartPopover.cartSize', { count: cartStore.size }, cartStore.size) }}
        </span>
      </div>

      <div class="max-h-[320px] overflow-auto relative mb-[92px] no-scrollbar">
        <RCartItems />
      </div>

      <div>
        <RCartFooter
          class="px-0 pt-4 pb-6 absolute bottom-0 m-auto w-[calc(100%_-_48px)] bg-white border-mercury rounded-b-2xl"
          :class="{
            'shadow-top-sm': cartStore.cart.items.length > 2,
          }"
        />
      </div>
    </template>
  </div>
</template>
