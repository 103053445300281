<script lang="ts" setup>
const { t } = useI18n()
const cartStore = useCartStore()
</script>

<template>
  <div>
    <RBaseDrawer
      v-model:show="cartStore.isOpen"
      v-bind="$attrs"
      placement="right"
      class="!rounded-r-none !rounded-l-xl max-w-[390px]"
      with-backdrop
      :title="t('components.cartDrawer.title')"
    >
      <div v-if="cartStore.isEmpty" class="flex justify-center items-center mt-36">
        <RCartEmptyState variant="button" @cta-click="cartStore.close" />
      </div>
      <div v-else class="max-h-full overflow-auto pb-44">
        <RCartItems />

        <RCartFooter
          ref="footerRef"
          is-mobile
          class="cart-drawer fixed left-0 right-0 bottom-0 w-full p-4 bg-white shadow-top-sm z-[55] overflow-hidden !rounded-bl-xl"
        />
      </div>
    </RBaseDrawer>
  </div>
</template>
